<template>
  <div>
    <FormFilter @set-action-filter="action = $event">
      <template slot="default">        
        <CInput id="tenant_product_id" label="ID" v-model="filter.tenant_product_id" />
        <CSelect id="product_id" label="Produto" :options="productOptions" v-model="filter.product_id" @update:value="filter.product_id = $event"/>
        <CSelect id="deployment_status_dm" label="Status Implantação" :options="deploymentOptions" v-model="filter.deployment_status_dm" @update:value="filter.deployment_status_dm = $event"/>
        <CInput id="business_proposal" label="Proposta Comercial" v-model="filter.business_proposal" />
        <CInput id="commercial_contract" label="Contrato Comercial" v-model="filter.commercial_contract" />
      </template>
    </FormFilter>
  </div>
</template>

<script>
import FormFilter from "@/components/FormFilter";

export default {
  name: "TenantProductFilter",
  props: { 
    productOptions: Array,
    deploymentOptions: Array
  },
  components: { FormFilter },
  data() {
    return {
      action: {},
      filter: {}
    }
  },
  methods: {
    apply() { 
      this.filter.apply = true
      this.triggerEvent()     
    },
    reset() {
      this.filter = {}
      this.filter.reset = true
      this.triggerEvent()   
    },
    triggerEvent() { 
      this.$emit('filter-tenant-product', this.filter);
      this.action = {}   
      if(this.filter.reset) this.filter = {}
    }
  },
  watch: {
    action() {
      if(this.action === 'applyFilter') {
        this.apply()     
      } else if(this.action === 'resetFilter') {
        this.reset()        
      }
    }
  }
}
</script>
