<template>
  <div>
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4" v-if="showFilter">
        <Filtrate @filter-tenant="filter = $event"></Filtrate>
      </CCol>
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" :gridEvents="gridEvents" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="4">
                <CInput v-if="record.tenant_id == null" id="tenant_id"  v-model="record.tenant_client_id">
                  <template #prepend-content>
                    <i class="fas fa-info-circle"/>
                  </template>
                </CInput>

                <CInput v-else-if="record.tenant_id != null" id="tenant_id" readonly v-model="record.tenant_id">
                  <template #prepend-content>
                    <i class="fas fa-info-circle"/>
                  </template>
                </CInput>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="3">
                <CInput
                  id="tenant_code"
                  label="CNPJ"
                  :readonly="readOnly"
                  v-model="record.tenant_code"
                />
              </CCol>
              <CCol sm="5">
                <CInput
                  id="tenant_name"
                  label="Cliente"
                  :readonly="readOnly"
                  v-model="record.tenant_name"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  id="Nome de Apresentação"
                  label="Nome Comercial"
                  :readonly="readOnly"
                  v-model="record.tenant_presentation"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="3">
                <CInput
                  id="serial_number"
                  label="Serial"
                  :readonly="readOnly"
                  v-model="record.serial_number"
                />
              </CCol>
              <CCol sm="2">
                <CInput
                  id="initial_dt"
                  label="Data Inicial"
                  type="date"
                  v-model="record.initial_dt"
                />
              </CCol>
              <CCol sm="2">
                <CInput
                  id="block_dt"
                  label="Data Bloqueio"
                  type="datetime-local"
                  readonly
                  v-model="record.block_dt"
                />
              </CCol>
              <CCol sm="2">
                <CInput
                  id="finish_dt"
                  label="Data Encerramento"
                  type="datetime-local"
                  readonly
                  v-model="record.finish_dt"
                />
              </CCol>
              <CCol sm="3">
                <CSelect
                  id="status_dm"
                  label="Situação"
                  :readonly="readOnly"
                  :options="statusOptions"
                  v-model="record.status_dm"
                  @update:value="record.status_dm = $event"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <CTextarea
                  id="description"
                  :readonly="readOnly"
                  label="Descrição"
                  rows="10"
                  v-model="record.description"
                />
              </CCol>
            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
import Filtrate from "./TenantFilter";

export default {
  name: "Tenant",
  props: {  },
  components: { Toolbars, Grid, Detail, Filtrate },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 8;
    },
    userAuth() {
      return this.$store.state.userAuth;
    },
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/tenant",
        primaryKey: "tenant_id",
        descriptionKey: "tenant_name",
        config: {
          child: false,
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: false,
          fields: null,
        },
        finds: []
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],
      //Variaveis Componente <Select>
      statusOptions: [],
      changeOptions: [],

      //Evento Customizado
      gridEvents: [],
      customEvent: {},

    }
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.setInitialFields()
        this.typeOptions = fn.finds.findValuesDomain(3);
        this.statusOptions = fn.finds.findValuesDomain(1);
        
      })
      this.afterInit()
    },
    async afterInit() {
      await this.$nextTick(() => {
        fn.getEvents(this)
        fn.getRecords(this)
      })
    },
    setInitialValues() {
      this.record.status_dm = 1
      fn.setAction(this)
    },
    async setInitialFields() {
      await this.$nextTick(() => {
        this.COMPONENT.config.fields = this.userAuth.owner === true ? 'manager':'default'
      })
    },
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== undefined && toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== undefined && toolbars !== null) {
          fn.setAction(this)
        }
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) {       
            this.record.tenant_client_id = gridRowSelected.tenant_id    
            gridRowSelected.tenant_client_id = gridRowSelected.tenant_id
            fn.loadRecord(this) 
            this.afterLoad()         
        }
      }
    },
    gridDbclick: {
      immediate: true,
      handler(gridRowActionSelected) {
        if (Object.entries(gridRowActionSelected).length !== 0 && this.userAuth.owner) {
          this.items = []
          let obj = {}
          obj.descriptionKey = `${this.gridDbclick[`${this.COMPONENT.primaryKey}`]} - ${this.gridDbclick[`${this.COMPONENT.descriptionKey}`]}`
          obj.parentName = this.$options.name
          obj.parentKey = this.COMPONENT.primaryKey
          obj.parentValue = this.gridDbclick[`${this.COMPONENT.primaryKey}`]
          this.$emit("set-hierarchy-level", obj);
        }
      }
    },
  },
  created() {
    this.init()
  },
};
</script>
