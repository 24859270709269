<template>
  <div class="px-2">
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4" v-if="showFilter">
        <Filtrate :productOptions="productOptions" :deploymentOptions="deploymentOptions" @filter-tenant-product="filter = $event"></Filtrate>
      </CCol>
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" :gridEvents="gridEvents" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="4">
                <CInput
                  id="tenant_product_id"
                  readonly
                  v-model="record.tenant_product_id">
                  <template #prepend-content
                    ><i class="fas fa-info-circle"
                  /></template>
                </CInput>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <CSelect
                  id="product_id"
                  label="Produto"
                  :readonly="readOnly"
                  :options="productOptions"
                  v-model="record.product_id"
                  @update:value="record.product_id = $event"
                />
              </CCol>
              <CCol sm="2">
                <CInput
                  id="initial_dt"
                  label="Data Início"
                  type="date"
                  v-model="record.initial_dt"
                />
              </CCol>
              <CCol sm="2">
                <CInput
                  id="block_dt"
                  label="Data Bloqueio"
                  type="date"
                  readonly
                  v-model="record.block_dt"
                />
              </CCol>
              <CCol sm="2">
                <CInput
                  id="finish_dt"
                  label="Data Encerramento"
                  type="date"
                  readonly
                  v-model="record.finish_dt"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <CSelect
                  id="deployment_status_dm"
                  label="Status Implantação"
                  :readonly="readOnly"
                  :options="deploymentOptions"
                  v-model="record.deployment_status_dm"
                  @update:value="record.deployment_status_dm = $event"
                />
              </CCol>

              <CCol sm="2">
                <CInput
                  id="installation_dt"
                  label="Data Instalação"
                  type="date"
                  v-model="record.installation_dt"
                />
              </CCol>
              <CCol sm="2">
                <CInput
                  id="homologation_dt"
                  label="Data Homologação"
                  type="date"
                  v-model="record.homologation_dt"
                />
              </CCol>
              <CCol sm="2">
                <CInput
                  id="production_dt"
                  label="Data Produção"
                  type="date"
                  v-model="record.production_dt"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="4">
                <CInput
                  id="business_proposal"
                  label="Proposta Comercial"
                  :readonly="readOnly"
                  v-model="record.business_proposal"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  id="commercial_contract"
                  label="Contrato Comercial"
                  :readonly="readOnly"
                  v-model="record.commercial_contract"
                />
              </CCol>
              <CCol sm="4">
                <CSelect
                  id="status_dm"
                  label="Status"
                  :readonly="readOnly"
                  :options="statusOptions"
                  v-model="record.status_dm"
                  @update:value="record.status_dm = $event"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <CTextarea
                  id="description"
                  label="Descrição"
                  :readonly="readOnly"
                  rows="2"
                  v-model="record.description"
                />
              </CCol>
            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
import Filtrate from "./TenantProductFilter";

export default {
  name: "TenantProduct",
  props: {
    parent: Object,
  },
  components: { Toolbars, Grid, Detail, Filtrate },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 8;
    },
    userAuth() {
      return this.$store.state.userAuth;
    },
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/tenant-product",
        primaryKey: "tenant_product_id",
        descriptionKey: "repository_name",
        config: {
          child: true,
          parentKey: "tenant_id",
          parentValue: "tenant_id",
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: false,
          fields: null,
          initialMethod: 'find-all-by-tenant',
        },
        finds: []
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],

      //Variaveis Componente <Select>
      productOptions: [],
      deploymentOptions: [],
      statusOptions: [],

      //Evento Customizado
      gridEvents: [],
      customEvent: {},
    };
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.setInitialFields()
        this.productOptions = fn.finds.findProducts();
        this.deploymentOptions = fn.finds.findValuesDomain(12);
        this.statusOptions = fn.finds.findValuesDomain(1);
      })
      this.afterInit()
    },
    async afterInit() {
      await this.$nextTick(() => {
        fn.getEvents(this)
        this.loadData()
      })
    },
    setInitialValues() {
      this.record.status_dm = 1
      fn.setAction(this)
    },
    async setInitialFields() {
      await this.$nextTick(() => {
        this.COMPONENT.config.fields = this.userAuth.owner === true ? 'manager':'default'
      })
    },
    loadData() {
      let param = {}
      param[`${this.COMPONENT.config.parentKey}`] = this.parent.parentValue
      param.tenant_client_id = this.parent.parentValue
      fn.getRecordsByMethod(this, param, this.COMPONENT.config.initialMethod) 
    }
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== null) {
          this.record.tenant_client_id =  this.record.tenant_id
          fn.setAction(this)
        }
        if(this.parent!== undefined) this.loadData()
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) { 
          this.record.tenant_client_id = gridRowSelected.tenant_id    
          gridRowSelected.tenant_client_id = gridRowSelected.tenant_id
          fn.loadRecord(this)    
        }
      }
    },
    parent: {
      immediate: true,
      handler(parent) {
        if(parent!== undefined && parent.parentKey === this.COMPONENT.config.parentKey) {
          this.init()
        } else {
          fn.reset(this)
        }
      }
    }
  }
}
</script>
