<template>
  <div>
    <FormFilter @set-action-filter="action = $event">
      <template slot="default">        
        <CInput id="tenant_id" label="ID" v-model="filter.tenant_id" />
        <CInput id="tenant_code" label="CNPJ" v-model="filter.tenant_code"/>
        <CInput id="tenant_name" label="Nome" v-model="filter.tenant_name"/>
        <CInput id="tenant_presentation" label="Apresentação" v-model="filter.tenant_presentation"/>
      </template>
    </FormFilter>
  </div>
</template>

<script>
import FormFilter from '@/components/FormFilter'

export default {
  name: 'TenantFilter',
  components: {FormFilter},
  data () {
    return {
      action: {},
      filter: {}
    }
  },
  methods: {
    apply() { 
      this.filter.apply = true
      this.triggerEvent()     
    },
    reset() {
      this.filter = {}
      this.filter.reset = true
      this.triggerEvent()   
    },
    triggerEvent() { 
      this.$emit('filter-tenant', this.filter);
      this.action = {}   
      if(this.filter.reset) this.filter = {}
    }
  },
  watch: {
    action() {
      if(this.action === 'applyFilter') {
        this.apply()     
      } else if(this.action === 'resetFilter') {
        this.reset()        
      }
    }
  }
}
</script>
